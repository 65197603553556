import React, { useState, useContext } from 'react';
import * as S from './ProductSelectorV2.styles';
import { addToCart } from '../../utils/addToCart';
import FunnelContext from '../../context/FunnelContext';
import { ProductPrice, CategorySavings } from '../../utils/productPrice';
import { Image } from '@builder.io/react';
import SubPopup from './SubPopup';

const ProductSelector = props => {
  const {
    tubs,
    subscriptionSchedules,
    OTPAppropriateSubVariant,
    hideBumpOfferDiscount,
    faqURL,
    bumpOfferPrefixText
  } = props;

  const context = useContext(FunnelContext);
  const {
    currency,
    currentCategory,
    setCurrentCategory,
    currentProduct,
    setCurrentProduct,
    path,
    funnelUrl,
    pageDesign,

    currentTubIndex,
    setCurrentTubIndex,
    tubIndex,
    setTubIndex,
    frequency,
    setFrequency,
    bumpOfferIndex,
    setBumpOfferIndex,
    bumpOfferChecked,
    setBumpOfferChecked,
    setSubmittedToCart
  } = context;

  const currencySymbol = currency?.symbol || '$';
  const currencyCode = currency?.code || 'USD';

  const [buttonClicked, setButtonClicked] = useState(false);
  const [showSubPopup, setShowSubPopup] = useState(false);

  const triggerAddToCart = () => {
    try {
      setButtonClicked(true);
      setSubmittedToCart(true);

      const extra = {
        ...JSON.parse(context.extra),
        setButtonClicked: setButtonClicked,
        currency_code: currencyCode,
        currency_symbol: currencySymbol
      };

      if (currentCategory === 'subscriptions') {
        const { discounts, products, bumpoffers } = context.subscription;
        const bumpOfferData = bumpOfferChecked
          ? bumpoffers[bumpOfferIndex]
          : {};
        const product = products[currentTubIndex];
        product.schedule = frequency;

        return addToCart(
          product,
          discounts,
          extra,
          bumpOfferData,
          path,
          funnelUrl
        );
      } else {
        const { discounts, products, bumpoffers } = context.onetime;
        const bumpOfferData = bumpOfferChecked
          ? bumpoffers[bumpOfferIndex]
          : {};
        const product = products[currentTubIndex];

        if (OTPAppropriateSubVariant) {
          const { discounts, products: subProducts } = context.subscription;
          product.subProduct = subProducts[currentTubIndex];
          product.subProduct.discounts = discounts;
        }

        return addToCart(
          product,
          discounts,
          extra,
          bumpOfferData,
          path,
          funnelUrl
        );
      }
    } catch (err) {
      setButtonClicked(false);
      setSubmittedToCart(false);
    }
  };

  const BumpOffer = ({ bumpoffers }) => {
    const handleBumpOffer = event => {
      setBumpOfferChecked(event.target.checked);
      setBumpOfferIndex(event.target.value);
    };

    const BumpOfferDiscount = ({ discount_percentage }) => {
      return hideBumpOfferDiscount ? (
        ``
      ) : (
        <span>({discount_percentage}% Off)</span>
      );
    };

    const prefix_text =
      bumpOfferPrefixText && bumpOfferPrefixText !== ``
        ? bumpOfferPrefixText
        : `Enhance Your Chews With Our`;

    return (
      <S.BumpOfferWrapper>
        {bumpoffers?.map(
          ({ checkoutData, discount_percentage, display_title }, index) => {
            return (
              <S.BumpOffer
                key={index}
                className={bumpOfferChecked ? 'active' : ''}
                pageDesign={pageDesign}
              >
                <S.BumpOfferLabel>
                  <S.BumpOfferCheckbox
                    type="checkbox"
                    value={index}
                    checked={bumpOfferChecked}
                    onChange={handleBumpOffer}
                  />
                  <S.BumpOfferContent>
                    <S.BumpOfferImage
                      alt="bumpoffer"
                      src={checkoutData.image}
                    />
                    <S.BumpOfferContentText pageDesign={pageDesign}>
                      {prefix_text} {display_title} for {currencySymbol}
                      {checkoutData.discounted_price.toFixed(2)}
                      <BumpOfferDiscount
                        discount_percentage={discount_percentage}
                      />
                    </S.BumpOfferContentText>
                  </S.BumpOfferContent>
                </S.BumpOfferLabel>
              </S.BumpOffer>
            );
          }
        )}
      </S.BumpOfferWrapper>
    );
  };

  const AddToCartButton = ({ children }) => {
    return (
      <S.AddToCartButton
        type="button"
        onClick={triggerAddToCart}
        disabled={buttonClicked ? 'disabled' : ''}
        className={buttonClicked ? 'processing' : ''}
        pageDesign={pageDesign}
      >
        <span>{children}</span>
      </S.AddToCartButton>
    );
  };

  const TubImage = ({ image, image2, active }) => {
    if (active) {
      return <Image image={image2} alt="" />;
    } else {
      return <Image image={image} alt="" />;
    }
  };

  const frequencyOnChange = evt => {
    setFrequency(evt.target.value);
  };

  const FrequencySelect = () => {
    if (subscriptionSchedules) {
      return (
        <S.Frequency>
          <p>Delivery Frequency</p>
          <select
            defaultValue={frequency}
            aria-label="Frequency"
            onBlur={frequencyOnChange}
            onChange={frequencyOnChange}
          >
            {subscriptionSchedules.map(({ id, label }) => {
              return (
                <option key={id} value={id}>
                  {label}
                </option>
              );
            })}
          </select>
        </S.Frequency>
      );
    } else {
      return '';
    }
  };

  const PriceComparison = () => {
    const discountedPrice = ProductPrice({
      type: `discounted_price`,
      currentCategory: currentCategory,
      currentProduct: currentProduct
    });
    const price = ProductPrice({
      type: `price`,
      currentCategory: currentCategory,
      currentProduct: currentProduct
    });

    return (
      <S.Price>
        {currencySymbol}
        {discountedPrice}{' '}
        {discountedPrice === price ? (
          ``
        ) : (
          <span>
            {currencySymbol}
            {price}
          </span>
        )}
      </S.Price>
    );
  };

  const TabContent = () => {
    if (currentCategory === `subscriptions`) {
      return (
        <>
          <PriceComparison />

          <FrequencySelect />

          <S.SubsPlan>
            <S.SubsPlanItem>
              <h3>
                <i className="icon icon-pencil"></i>
                Flexible subscription plan
              </h3>
              <p>Change, pause or skip your delivery</p>
            </S.SubsPlanItem>
            <S.SubsPlanItem>
              <h3>
                <i className="icon icon-close"></i>
                Easy Cancel
              </h3>
              <p>
                You can{' '}
                <S.SubPopup onClick={() => setShowSubPopup(true)}>
                  manage or cancel your subscription online
                </S.SubPopup>
              </p>
            </S.SubsPlanItem>
          </S.SubsPlan>
        </>
      );
    } else {
      return (
        <S.OnetimeContent>
          <PriceComparison />

          <p>
            You can have the same for only {currencySymbol}
            <ProductPrice
              type="discounted_price"
              currentCategory="subscriptions"
              currentProduct={`subscription_${tubIndex}`}
            />{' '}
            if you subscribe. That’s a saving of {currencySymbol}
            <CategorySavings type="discounted_price" tub={tubIndex} /> and you
            can change or cancel your subscription! 😱😱😱
          </p>
        </S.OnetimeContent>
      );
    }
  };

  const Savings = ({ currentCategory, currentProduct }) => {
    const savings = ProductPrice({
      type: 'savings',
      currentCategory: currentCategory,
      currentProduct: currentProduct
    });
    if (parseFloat(savings)) {
      return (
        <span>
          (save {currencySymbol}
          {savings})
        </span>
      );
    } else {
      return ``;
    }
  };

  return (
    <FunnelContext.Consumer>
      {funnelData => (
        <S.Container>
          <S.Label>1. Quantity</S.Label>
          <S.TubList>
            {tubs?.map(({ title, image, image2 }, index) => {
              const activeTub = currentTubIndex === index ? 'current' : '';
              const hoverTub = tubIndex === index ? 'active' : '';
              const activeImage =
                tubIndex === index || currentTubIndex === index;

              return (
                <S.TubListItem
                  key={index}
                  className={`${hoverTub} ${activeTub}`}
                  onClick={() => {
                    setTubIndex(index);
                    setCurrentTubIndex(index);
                    setCurrentProduct(
                      currentCategory === `subscriptions`
                        ? `subscription_${index}`
                        : `onetime_${index}`
                    );
                  }}
                >
                  <S.TubListItemContainer>
                    <TubImage
                      image={image}
                      image2={image2}
                      active={activeImage}
                    />
                    <span>{title}</span>
                  </S.TubListItemContainer>
                </S.TubListItem>
              );
            })}
          </S.TubList>

          <S.Label>2. Package</S.Label>
          <S.PackageContainer>
            <S.Tabs>
              <S.TabItem
                className={currentCategory === `subscriptions` ? 'active' : ''}
              >
                <label>
                  <input
                    type="radio"
                    name="category"
                    onChange={() => {
                      if (typeof setCurrentCategory === 'function') {
                        setCurrentCategory('subscriptions');
                        setCurrentProduct(`subscription_${currentTubIndex}`);
                      }
                    }}
                    checked={
                      currentCategory === `subscriptions` ? 'checked' : ''
                    }
                  />
                  <S.TabItemTitle>
                    Subscribe{' '}
                    <Savings
                      currentCategory="subscriptions"
                      currentProduct={`subscription_${currentTubIndex}`}
                    />
                  </S.TabItemTitle>
                </label>
              </S.TabItem>
              <S.TabItem
                className={currentCategory === `onetime` ? 'active' : ''}
              >
                <label>
                  <input
                    type="radio"
                    name="category"
                    onChange={() => {
                      if (typeof setCurrentCategory === 'function') {
                        setCurrentCategory('onetime');
                        setCurrentProduct(`onetime_${currentTubIndex}`);
                      }
                    }}
                    checked={currentCategory === `onetime` ? 'checked' : ''}
                  />
                  <S.TabItemTitle>
                    One-Time{' '}
                    <Savings
                      currentCategory="onetime"
                      currentProduct={`onetime_${currentTubIndex}`}
                    />
                  </S.TabItemTitle>
                </label>
              </S.TabItem>
            </S.Tabs>

            <S.TabContent>
              <TabContent />
            </S.TabContent>
          </S.PackageContainer>

          <BumpOffer
            bumpoffers={
              currentCategory === `onetime`
                ? funnelData?.onetime?.bumpoffers
                : funnelData?.subscription?.bumpoffers
            }
          />

          <AddToCartButton>Add To Cart</AddToCartButton>
          <SubPopup
            show={showSubPopup}
            setShowSubPopup={setShowSubPopup}
            faqURL={faqURL}
          />
        </S.Container>
      )}
    </FunnelContext.Consumer>
  );
};

export default ProductSelector;
